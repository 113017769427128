import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section, Image, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Rem's Barber Studio
			</title>
			<meta name={"description"} content={"Ваши волосы, наше мастерство"} />
			<meta property={"og:title"} content={"Rem's Barber Studio"} />
			<meta property={"og:description"} content={"Ваши волосы, наше мастерство"} />
			<meta property={"og:image"} content={"https://optirealpro.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://optirealpro.com/img/34613145758.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://optirealpro.com/img/34613145758.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://optirealpro.com/img/34613145758.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://optirealpro.com/img/34613145758.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://optirealpro.com/img/34613145758.png"} />
			<meta name={"msapplication-TileImage"} content={"https://optirealpro.com/img/34613145758.png"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://optirealpro.com/img/4.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="670px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
				О нас
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
				Барбершоп Rem's Barber, основанный в страсти к парикмахерскому искусству, с самого начала своей деятельности посвятил себя предоставлению превосходных услуг по уходу за собой. Наш путь начался с простой цели: создать пространство, где клиенты могли бы получать высококачественные стрижки и бритье в непринужденной и гостеприимной обстановке. С годами мы росли и развивались, но наше стремление предоставлять первоклассный сервис оставалось неизменным.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-8">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
			Наша команда парикмахеров-экспертов
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
			В Rem's Barber наша команда парикмахеров - наш главный актив. Каждый парикмахер обладает богатым опытом и внимательным взглядом на детали, гарантируя, что каждый клиент будет выглядеть наилучшим образом. Наши парикмахеры проходят постоянное обучение, чтобы быть в курсе последних тенденций и техник, что позволяет им удовлетворять широкий спектр стилей и предпочтений. Независимо от того, ищете ли вы классическую стрижку или современный стиль, наши опытные парикмахеры готовы предоставить индивидуальные услуги с учетом ваших потребностей.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Наследие и инновации: 
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Мы сочетаем традиционные парикмахерские техники с современными стилями, предлагая лучшее из двух миров.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Внимание к деталям: 
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Каждая услуга предоставляется с точностью и заботой, гарантируя, что даже самые мелкие детали будут идеальными.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Качественные продукты: 
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Мы используем первоклассные средства для ухода, которые улучшают здоровье и внешний вид ваших волос и кожи.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://optirealpro.com/img/5.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Станьте частью сообщества Rem's Barber
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Мы приглашаем вас испытать исключительные услуги барбершопа Rem's Barber. Независимо от того, являетесь ли вы нашим давним клиентом или впервые пришли в салон, наша цель - сделать так, чтобы вы почувствовали себя частью нашего сообщества. С того момента, как вы войдете в наши двери, к вам будут относиться с максимальной заботой и уважением. Присоединяйтесь к нам и узнайте, почему Rem's Barber является предпочтительным выбором для взыскательных клиентов.
					<br />
					<br />
					<br />
					<br />
					<Strong>
					Преобразите свой внешний вид и почувствуйте себя лучше всех с Rem's Barber.
					</Strong>
				</Text>
			</Box>
		</Section>
		<Components.New />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});